import React, { useState, useEffect, useContext } from "react";
import { IoClose } from "react-icons/io5";
import AppContext from '../../contexts/AppContext';
import axios from 'axios';

const FrontNews = ({ articles }) => {
  const { userData, setUserData } = useContext(AppContext);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen && selectedArticle) {
      console.log(selectedArticle);
    }
  }, [modalOpen, selectedArticle]);

  const suppressHtmlTags = (html) => {
    return html ? html.replace(/<[^>]+>/g, '') : '';
  };

  // Get the first article
  const [firstArticle, ...remainingArticles] = articles;

  const openModal = (article) => {
    setSelectedArticle(article);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArticle(null);
    setModalOpen(false);
  };

  return (
    <div className="p-4">
      <div className={modalOpen ? "pointer-events-none" : ""}>
        <div className="flex flex-col md:flex-row">
          <div className="bg-gray-200 rounded-lg p-4 md:p-6 m-4 flex-1">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-4">Latest</h2>
            <div className="bg-cover text-center overflow-hidden hover:scale-110 transition duration-300 ease-in-out"
              style={{
                minHeight: '200px',
                backgroundImage: firstArticle?.articlePhotos?.[0]
                  ? `url(${firstArticle.articlePhotos[0].photoUrl})`
                  : ''
              }}
              title="">
            </div>
            <div className="p-2 bg-white rounded-b flex flex-col justify-between leading-normal">
              <span className="text-gray-900 font-bold text-lg my-2">
                {firstArticle?.title || 'Default Title'}
              </span>
              <span className="text-gray-700 text-sm p-0 text-justify" dangerouslySetInnerHTML={{ __html: suppressHtmlTags(firstArticle?.content || '') }}></span>
            </div>
          </div>
          <div className="bg-gray-200 rounded-lg p-4 md:p-6 m-4 flex-1">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-4">Popular Events</h2>
            <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              {remainingArticles.map(article => (
                <div
                  key={article.id}
                  className="relative rounded overflow-hidden cursor-pointer"
                  style={{ height: '200px', position: 'relative' }}
                  onClick={() => openModal(article)}
                >
                  <img
                    className="w-full h-full transition duration-300 ease-in-out transform hover:scale-110"
                    src={article?.articlePhotos[0]?.photoUrl}
                    alt={`Photo ${article.title}`}
                    style={{ objectFit: 'cover' }}
                  />
                  <div className="absolute inset-x-0 bottom-0 bg-white text-dark text-sm font-medium transform transition duration-300 ease-in-out hover:bg-opacity-60">
                    <p className="text-sm p-1">
                      {article.title.split('.').map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1)).join('.')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {modalOpen && selectedArticle && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={closeModal}></div>
          <div className="relative bg-white p-4 md:p-8 max-w-xl w-full max-h-full overflow-auto z-50 rounded-lg shadow-lg">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
            >
              <IoClose size={24} />
            </button>

            <div
              className="mt-4 text-center overflow-hidden rounded-lg transition duration-300 ease-in-out"
              style={{
                minHeight: '200px',
                backgroundImage: selectedArticle?.articlePhotos?.[0]
                  ? `url(${selectedArticle.articlePhotos[0].photoUrl})`
                  : ''
              }}
              title="">
            </div>
            <h2 className="text-lg uppercase font-bold my-3">{selectedArticle?.title}</h2>
            <p className="text-justify" dangerouslySetInnerHTML={{ __html: suppressHtmlTags(selectedArticle?.content || '') }}></p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FrontNews;
