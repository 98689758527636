//HeroSection
import React from 'react';
import { Button } from './Button';
import './herosection.css';

const HeroSection = ({ imgUrl, title, description, onClosePhotoGallery }) => {


  // const processedDescription = description ? 
    // description.charAt(0).toUpperCase() + description.slice(1).toLowerCase() : '';
    

  // Check if title is provided and not undefined
  const processedTitle = title ? title.toUpperCase() : '';

  return (
    <div className='relative'>
      {/* Video */}
      <video className="object-cover w-full h-full fixed z-0">
        {/* Add video source */}
      </video>

      {/* Hero container */}
    
    
      <div 
        className='hero__container relative z-10 flex flex-col 
        justify-center items-center bg-black bg-opacity-50 py-20'
        style={{
          backgroundImage: `url(${imgUrl})`, 
          backgroundSize: 'cover', backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat'}}
        >
        {/* Check if processedTitle exists before using it */}
        {processedTitle && <h1 className="text-white text-4xl md:text-5xl lg:text-6xl">{processedTitle}</h1>}
        {/* Check if processedDescription exists before using it */}
        {description && (
  <p
    className="text-white text-3xl md:text-4xl lg:text-3xl mt-4"
    dangerouslySetInnerHTML={{ __html: description }}
  ></p>
)}
   
        {/* Hero buttons */}
        <div className=" flex hero-btns mt-8">
          
          <Button  
            className='btns'
            buttonStyle='btn--primary' 
            buttonSize='btn--large'
            page='/ourstory'
          >

          <span >
            WATCH OUR STORY <i className='far fa-play-circle'></i>
          </span>
        </Button>
        <Button  
          className='btns'
          buttonStyle='btn--primary' 
          buttonSize='btn--large'
          page='/PhotoGallery'
        >

  <span >
    PHOTO GALLERY <i className='far fa-play-circle'></i>
  </span>
</Button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
