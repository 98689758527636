import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';
import { BsFillSendFill } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";

const AddUser = ({ FetchUsers }) => {

//const [user, setUser] = useState([{ username: '', first: '', password: '', role_id:'', img:'',imgType:'',displayImg:'',imgUrl:'',}]);
const [user, setUser] = useState({
  first_name: '',
  title: '',
  username: '',
  role_id: '',
  password:'12345',
  photo: {}
});

const [fullName, setFullName] = useState('');
const [email, setEmail] = useState('');
const [title, setTitle] = useState('');
const [roleId, setRoleId] = useState('');
const [imgType, setImgType] = useState('');
const [displayImg, setDisplayImg] = useState('');
const [roles, setRoles] = useState([]);
const [isLoading,setIsLoading]=useState(false);


const { userData, } = useContext(AppContext);

useEffect(() => {
 
  fetchRoles();

}, []);




  

const resetForm = () => {
  const updatedUser = { 
      first_name: '',
      last_name: '',
      title: '',
      username:'',
      role_id:'',
      password:'12345',
      photo:{},
      
  };
  setUser(updatedUser);
  setFullName('');
  setEmail('');
  setTitle('');
  setRoleId('');
  setDisplayImg('');
  setImgType('');


 

};
  
  const fetchRoles= async () => {
   // console.log('here sir in fetch users ',userData.token)
    const config = { headers: { Authorization: `Bearer ${userData.token}` },};
    try {
      const response = await axios.get('https://www.eldsmw.org:5000/api/user_roles',config);
      const data = response.data;

     console.table('API Response:', data); // Log the response
      setRoles(data)
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch data. Please try again later.',
      };
    }
  };

  const cleanObject = (obj) => { // remove empty values in user object
    if (obj === null || obj === undefined) {return null;}

    if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
            return obj
                .map(cleanObject)
                .filter(item => item !== null && item !== undefined && item !== '');
        } else {
            const cleanedObj = Object.fromEntries(
                Object.entries(obj)
                    .map(([key, value]) => [key, cleanObject(value)])
                    .filter(([key, value]) => value !== null && value !== undefined && value !== '')
            );
            return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
        }
    }
    
    return obj;
};
  
  const handleImgChange = (event) => {
    const { name, files } = event.target;
    if (name === "img") {
        convertFile(files);
    }
};

const convertFile = (files) => {
    console.log('conviert images')
    if (files && files.length > 0 && files[0] instanceof File) {
        const fileRef = files[0];
        const fileType = fileRef.type;
        const reader = new FileReader();
        reader.readAsBinaryString(fileRef);
        reader.onload = (ev) => {
        const  photo= {data:btoa(ev.target.result),contentType:fileType};
        setDisplayImg(btoa(ev.target.result))
        setImgType(fileType)
       // setUser(prevUser => ({...prevUser,photo:photo}));
    };
  }
};

   // Handling input changes
   const handleTextChange = (event) => {
    const { name, value } = event.target;
    setUser(prevUser => {
      const updatedUser = { ...prevUser };
      switch (name) {
        case 'fullName':
          setFullName(value);
          updatedUser['first_name'] = value; // Use 'first_name' as a key
          break;
        case 'email':
          setEmail(value);
          updatedUser['username'] = value; // Use 'last_name' as a key
          break;
        case 'title':
          setTitle(value);
          updatedUser['title'] = value;
          break;
        case 'roleId':
       
          setRoleId(value);
          const selectedRole = roles.find(role => role.name === value);
          console.log(('selectedRole' ,selectedRole ))
          if (selectedRole) { updatedUser['role_id'] = selectedRole.id;}
          //updatedUser['role_id'] = value;

          break;
        default:
          break;
      }
      return updatedUser;
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Required field checks
    console.log('user before submit',user)
    if (!user.first_name){toast.error('Please enter First name');return;  }// Exit function if required fields are missing
    if (!user.username){toast.error('Please enter email address');return;  }// Exit function if required fields are missing
    if (!user.title){toast.error('Please enter Job title');return; }
    if (!user.role_id){toast.error('Please enter user role');return; }
    const cleanedData = cleanObject(user);

    setIsLoading(true);
    // Construct userPhotos array
    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
      params: cleanedData.id ? { id: cleanedData.id } : {}, // Send profile.id as a query parameter if present
    };
    console.log('cleaned data',cleanedData)
    try {
      const response = await axios.post('https://www.eldsmw.org:5000/api/users', cleanedData, config);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      toast.success('user submitted successfully!');
      FetchUsers();
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding user:', err);
      throw new Error('Failed to add user');
    } finally {
      setIsLoading(false);
    }
  };

   console.log('user roles',roles)

  const photo_url =  `data:${imgType};base64,${displayImg}`
  

  return (
    <>
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">User</h2>
            </div>
            <div>
                {isLoading ? (
                    <div className="flex items-center flex-col">
                        <Audio height="80" width="80" radius="9" color="slate-500" ariaLabel="three-dots-loading" />
                        <p className="mt-2">Processing. Please wait...</p>
                    </div>
                ) : (
                    <>
                        {/* menu icon */}
                        <div className="w-full sm:w-auto sm: px-4 py-2 flex flex-row justify-end">
                        <button
                                        className={`bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300 `}
                                        title="Delete"
                                        onClick={resetForm}
                                    >
                                        <FcCancel size={20} color="blue" />
                                    </button>
                            <button
                                className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 `}
                                title="Save"
                                onClick={handleSubmit}
                            >
                                <BsFillSendFill size={20} color="blue" />
                            </button>
                        </div>
                        {/* end menu icons */}

                        <div className="">
                            <ToastContainer position="top-center" autoClose={2000} />
                            <div className="max-w-xl mx-auto">
                                {isLoading && (
                                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                                    </div>
                                )}
                                <div className="w-full">
                                    <form className="shadow-md rounded px-8 pt-4 mb-2">
                                        <div className="relative z-0 mb-8 group">
                                            <input
                                                type="text"
                                                className="block py-2.5 px-0 w-full text-sm text-black  bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                name="fullName"
                                                onChange={(event) => handleTextChange(event)}
                                                value={fullName}
                                            
                                            />
                                            <label
                                                htmlFor="productname"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                               Full name 
                                            </label>
                                        </div>

                                        <div className="relative z-0 mb-8 group">
                                            <input
                                                type="text"
                                                className="block py-2.5 px-0 w-full text-sm text-black  bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                name="email"
                                                onChange={(event) => handleTextChange(event)}
                                                value={email}
                                          
                                            />
                                            <label
                                                htmlFor="productname"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                              Email Address
                                            </label>
                                        </div>

                                        <div className="relative z-0 mb-8 group">
                                            <input
                                                type="text"
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                name="title"
                                                onChange={(event) => handleTextChange(event)}
                                                value={title}
                                          
                                            />
                                            <label
                                                htmlFor="title"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                               Job Title
                                            </label>
                                        </div>
                                        <div className="relative z-0 mb-8 group">
                                            <input
                                                type="text"
                                                name="roleId"
                                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                list="rolesList" // Link the input to the datalist
                                                onChange={(event) => handleTextChange(event)}
                                                value={roleId}
                                            
                                            />
                                            <datalist id="rolesList">
                                                {roles && roles.map((role) => (
                                                    <option key={role?.id} value={role?.name} />
                                                ))}
                                            </datalist>
                                            <label
                                                htmlFor="page"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                               User role 
                                            </label>
                                        </div>
                                       
                                        
                                        <div className="relative z-0 mb-3 w-full group flex items-center">
                                            <input
                                                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                                                type="file"
                                                accept="image/*"
                                                name='img'
                                                onChange={(event) => handleImgChange(event)}
                                            
                                            />
                                            <label
                                                htmlFor="img"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Upload User Profile Picture
                                            </label>
                                            
                                            {photo_url && (
                                              <img
                                                className="rounded-full h-24 w-24"
                                                src={photo_url}
                                                alt={photo_url ? user?.first_name : ""}
                                              />
                                            )}
                                            
                                          
                                        </div>
                                     
                                            <div className="text-center mb-2">
                                                <button
                                                    className="px-4 py-2 mb-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-slate-500 focus:outline-none focus:bg-blue-600"
                                                    onClick={handleSubmit}
                                                   
                                                >
                                                    Save
                                                </button>
                                            </div>
                                   
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
        <ToastContainer />
    </>
);
}

export default AddUser
