import React from 'react'
import axios from 'axios';

import { useState, useEffect, useRef, useContext } from "react";
import AppContext from '../contexts/AppContext';
import { useNavigate } from "react-router-dom";
import {FcGoogle} from 'react-icons/fc'
import {AiFillFacebook} from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
//import { loginUser} from '../api/loginService.js';



const Login = ({imgUrl})=> {
    const userRef = useRef();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const { userData, setUserData,updateCount, setUpdateCount } = useContext(AppContext);
   
    useEffect(() => {// Check if userRef is defined before calling focus
        if (userRef.current) {
            userRef.current.focus();
        }
    }, []); // Empty dependency array ensures it runs only on mount

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
      
        try {
          const response = await axios.post('https://www.eldsmw.org:5000/api/users/login', { username, password });
          
          // Check if the login was successful based on the response
          if (response.data.token) {
            setUserData(response.data)
            setUpdateCount(updateCount+1) // to trigger reload  of users list in contextProvider 
            //setIsLoggedIn(true);
            // toast.error(error.message); // Display the error message to the user
             navigate('/dashboard');
            // Perform actions for successful authentication (e.g., store token, redirect)
          } else {
            toast.error('User does not exist');
        
            // Display an error message to the user
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          toast.error('Error during authentication');
        } finally {
            setUserData(prevUser => {
                const updatedUser = { ...prevUser };
                updatedUser.isLoggedIn= true;
                return updatedUser;
            })
            
           setIsLoading(false);
           navigate('/dashboard');
         
        }
          
      };
      
    

    return (
        <>
            <ToastContainer position="top-center" autoClose={2000} />
            <div className='relative w-full h-screen bg-zinc-900/90 rounded-2xl'>
                {isLoading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                    <Audio type="Bars" color="#FFF" height={50} width={50} />
                </div>
                )}
                 <img 
                    className='absolute w-full h-full object-cover mix-blend-overlay' 
                    src={imgUrl} alt="/" 
                /> 
                <div className='flex justify-center items-center h-full'>
                    <form onSubmit={handleSubmit} className='max-w-[400px] w-full mx-auto bg-white p-8 rounded-lg'>
                        <h2 className='text-4xl font-bold text-center py-4'>ELDS LOGIN.</h2>
                        {/* <div className='flex justify-between py-8'>
                            <p 
                                className='border shadow-lg hover:shadow-xl px-6 py-2 relative
                                 flex items-center'><AiFillFacebook className='mr-2' /> Facebook
                            </p>
                            <p 
                                className='border shadow-lg hover:shadow-xl px-6 py-2 relative
                                 flex items-center'><FcGoogle className='mr-2' /> Google
                            </p>
                        </div> */}
                        <div className='flex flex-col mb-4'>
                            <label>Username</label>
                            <input
                                onChange={(e) => setUsername(e.target.value)}
                                className="border relative bg-gray-100 p-2"
                                type="text"
                                id="username"
                                placeholder="Username"
                                ref={userRef}
                                autoComplete="off"
                                required
                                value={username}
                            />
                        </div>
                        <div className='flex flex-col '>
                            <label>Password</label>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                className="border relative bg-gray-100 p-2"
                                type="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                required
                            />
                        </div>
                        <button className='w-full py-3 mt-8 bg-blue-400 hover:bg-indigo-300 
                            relative text-white rounded-lg'>Sign In
                        </button>
                        <p className='flex items-center mt-2'><input className='mr-2' type="checkbox"  />Remember Me</p>
                        <p className='text-center mt-8'>  Forgot Username or Password?</p>
                    </form>
                </div>
            </div>
        </>
    )
} ;
export default Login;
